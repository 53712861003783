/* You can add global styles to this file, and also import other style files */
@import "assets/fonts/GillSans/stylesheet.css";

* {
  box-sizing: border-box;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  --mat-dialog-transition-duration: 0ms !important;

  ::-moz-focus-outer,
  ::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  transition: opacity 2.5s;
  visibility: visible;
  opacity: 1;
  height: 100vh;
  position: relative;
  font-family: "Gill Sans", sans-serif !important;
  background-color: #263038;
  overflow: hidden;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

blockquote {
  margin: 0;
}

img {
  display: block;
  max-width: 100%;
}

fieldset {
  border: none;
  padding: 0;
}

button {
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
}

html,
body {
  height: 100%;
}

@keyframes scale {
  0% {
    transform: scale(0.95);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(0.95);
  }
}

@import "assets/styles/button";
